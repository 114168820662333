import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const StudioBlock = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 40% 60%;
  margin-bottom: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  .studioContent {
    display: grid;
    grid-row-start: span 2;
    align-items: center;
  }

  .dualImage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .studioImage4 {
    display: grid;
  }
`

const Studio = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <StudioBlock>
      <div className="studioContent">
        <div>
          <h2>{data.wordpressPage.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.content,
            }}
          />
        </div>
      </div>
      <div className="studioImage1">
        <Img fluid={data.studio1.localFile.childImageSharp.fluid} />
      </div>
      <div className="dualImage">
        <div className="studioImage2">
          <Img fluid={data.studio2.localFile.childImageSharp.fluid} />
        </div>
        <div className="studioImage3">
          <Img fluid={data.studio3.localFile.childImageSharp.fluid} />
        </div>
      </div>
      <div className="studioImage4">
        <Img fluid={data.studio4.localFile.childImageSharp.fluid} />
      </div>{' '}
      <div className="studioImage5">
        <Img fluid={data.studio5.localFile.childImageSharp.fluid} />
      </div>
    </StudioBlock>
  </Layout>
)

export default Studio

export const query = graphql`
  query StudioQuery {
    wordpressPage(slug: { eq: "studio" }) {
      title
      content
    }
    studio1: wordpressWpMedia(title: { eq: "Studio1" }) {
      title
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    studio2: wordpressWpMedia(title: { eq: "Studio2" }) {
      title
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    studio3: wordpressWpMedia(title: { eq: "Studio3" }) {
      title
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    studio4: wordpressWpMedia(title: { eq: "Studio4" }) {
      title
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    studio5: wordpressWpMedia(title: { eq: "Studio5" }) {
      title
      localFile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`
